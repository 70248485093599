<template>
  <div>
    <el-dialog
        title="会员中心"
        v-model="dialogVisible"
        width="350px"
        :before-close="handleClose"
    >
      <el-form ref="paymentForm" label-width="100px">
        <el-form-item label="会员姓名">
          <span>{{ xm }}</span>
        </el-form-item>
        <el-form-item label="会员有效期">
          <span style="color: red">{{ formattedDueTime + '日到期' }}</span>
        </el-form-item>

        <div style="text-align: center">
          <el-button type="primary" class="payment-button" @click="renewVip">
            <div>
              <svg-icon icon="icon10" style="display: inline-block;padding-right: 10px"/>
              <span>续费会员</span>
            </div>
          </el-button>
        </div>
      </el-form>
    </el-dialog>
    <Vip ref="vip"/>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import Vip from '@/views/vip/vip.vue'
import dayjs from 'dayjs';

export default {
  name: 'vip-zx',
  components: {Vip},
  data() {
    return {
      dialogVisible: false,
    };
  },
  computed: {
    ...mapState(['xm']),
    formattedDueTime() {
      const date = dayjs(this.$store.state.term);
      return date.format('YYYY-MM-DD');
    },
  },
  methods: {
    handleClose() {
      this.dialogVisible = false;
    },
    renewVip() {
      this.dialogVisible = false
      // this.$router.push({path: '/vip'});
      this.$refs.vip.drawer = true
    }
  }
};
</script>

<style lang="scss" scoped>
.dialog-footer {
  padding: 10px;
  text-align: right;
  background-color: #FFFFFF;
}
/deep/ .el-dialog__footer {
  padding: 0;
}
/deep/ .el-dialog__header{
  padding: 15px 0 15px 0;
  text-align: center;
  background-color: #409eff;
}
/deep/ .el-dialog__title{
  text-align: center !important;
  color: #FFFFFF;
}

/deep/ .el-icon-close{
  color: #FFFFFF !important;
}

.payment-button {
  cursor: pointer;
  width: 130px;
  height: 45px;
  border-radius: 10px;
  margin-top: 20px;

  div {
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
