<template>
  <div class="home">
    <header>
      <div class="logo">
        <img src="../../assets/student/logo.png" alt="">
      </div>
      <!-- <div class="person">
        <img src="../../assets/student/portrait.png" alt="">
        <span> {{store.state.userInfo.realname || '未登录'}} </span>
      </div> -->
      <el-dropdown  @command="returnLogin" :hide-on-click="false">
        <div class="el-dropdown-link person">
          <svg-icon icon="icon7" v-if="fellow" style="position: relative;top: -10px;left: 15px"></svg-icon>
          <img src="../../assets/student/portrait.png" alt="">
          <span>{{ store.state.userInfo.xm }}</span>
        </div>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item command="20">设置</el-dropdown-item>
            <el-dropdown-item command="myVip" v-if="fellow">我的会员</el-dropdown-item>
            <el-dropdown-item command="goVip" v-else>开通会员</el-dropdown-item>
            <el-dropdown-item command="15">修改密码</el-dropdown-item>
            <el-dropdown-item command="10">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </header>
    <main>
      <!-- 助记符 -->
      <div class="zjf">
        <img @click="clickhandler('zjf')" src="../../assets/student/menuIcon0.png" alt="">
        <p @click="clickhandler('zjf')">认识助记符</p>
      </div>
      <!-- 图谱英语 -->
      <div class="tuyy">
        <img @click="clickhandler('tpyy')" src="../../assets/student/menuIcon1.png" alt="">
        <p @click="clickhandler('tpyy')">图谱英语</p>
      </div>
      <!-- 学习报告 -->
      <div class="xxbg">
        <img @click="clickhandler('xxbg')" src="../../assets/student/menuIcon2.png" alt="">
        <p @click="clickhandler('xxbg')">学习报告</p>
      </div>
      <!-- 个人中心 -->
      <div class="grzx">
        <img @click="clickhandler('grzx')" src="../../assets/student/menuIcon3.png" alt="">
        <p @click="clickhandler('grzx')">个人中心</p>
      </div>
    </main>
  </div>
  <Footer/>
  <SymbolsPopup v-if="box_data.dialogVoice" :box_data="box_data" @popupChange="popupChange"/>
  <Signout :exit_data="exit_data" v-if="exit_data.isShow"/>
  <AlterPassword ref="formRef"/>
  <Vip ref="membership"/>
  <MyVip ref="myVip"/>
</template>
<script>
import {getmnemonic, myPersonalConfig} from "@/api/student.js";
import {useRouter} from "vue-router";
import {onMounted, reactive, toRefs, ref, computed} from "vue";
import Footer from "./components/footer.vue";
import SymbolsPopup from './components/pronunciation.vue'
import {isHaveTrain, isCanTp, getAPPID} from "@/api/student";
import {ElMessage} from "element-plus";
import {useStore} from "vuex";
import Signout from './components/signout.vue'
import AlterPassword from '../../components/aboutPassword/alterPassword.vue'

import Vip from '@/views/vip/vip.vue'
import MyVip from "@/views/vip/myVip.vue";

export default {
  components: {
    Footer,
    SymbolsPopup,
    Signout,
    AlterPassword,
    Vip,
    MyVip
  },
  setup() {
    const membership = ref(null);
    const myVip = ref(null);
    const formRef = ref(null)
    const data = reactive({
      jxList: [],
      box_data: {
        dialogVoice: false,
        sysVoices: 'y'
      },
      exit_data: { // 控制退出登录弹窗显示隐藏
        isShow: false
      }
    });
    // 选择发音弹窗回调
    const popupChange = (val) => {
      data.box_data.dialogVoice = val.dialogVoice
    }
    const router = useRouter();
    const store = useStore();
    const fellow = computed(() => store.state.fellow);
    // 点击助记符和图谱英语判断是否能进入

    // 点击模块
    const clickhandler = (val) => {
      // 助记符
      if (val == "zjf") {
        if(fellow.value){
          router.replace({name: "mnemonic"});
        }else{
          ElMessage({
            message: '请先开通会员再来学习助记符吧！',
            type: 'warning'
          })
        }

        // 图谱英语
      } else if (val == "tpyy") {

        // 判断学生是否可以进入图谱英语， 0可以，1不可以
        isCanTp({
          bjid: data.jxList.bjid,
          jxid: data.jxList.jxid,
          xsid: store.state.userInfo.yhid
        }).then(res => {
          if (res.success) {
            if (!res.result.isCan) {
              if(fellow.value){
                router.replace({name: "newEnglish"});
              }else{
                ElMessage({
                  message: '请先开通会员再来学习图谱英语吧！',
                  type: 'warning'
                })
              }
            } else {
              ElMessage({
                message: '请先学习完助记符再来吧！',
                type: 'warning'
              })
            }
          } else {
            ElMessage({
              message: res.resultDesc,
              type: 'warning'
            })
          }
        })
      } else if (val == "xxbg") {

        if(fellow.value){
          router.replace({name: "studyReport"});
        }else{
          ElMessage({
            message: '请先开通会员再来查看学习报告吧！',
            type: 'warning'
          })
        }
      } else if (val == 'grzx') {
        router.replace({path: '/personalCenter/coins'})
      }
    };
    onMounted(() => {
      getAPPID().then(res => {
        if (res.success) {
          if (!localStorage.getItem(new Date().Format('yyyy-MM-dd') + 'KDXFNumber')) {
            localStorage.setItem(new Date().Format('yyyy-MM-dd') + 'KDXFNumber', '0')
          }
          sessionStorage.setItem('APPID', res.result.appid)
          sessionStorage.setItem('API_SECRET', res.result.apiSecret)
          sessionStorage.setItem('API_KEY', res.result.apiKey)
          console.log(res.result.appid, res.result.apiSecret, res.result.apiKey, 111111)
        } else {
          ElMessage({
            message: "语音识别标识获取失败，请刷新后重试",
            type: "warning",
          });
        }
      })
      myPersonalConfig({xsid: store.state.userInfo.yhid}).then(res => {
        // 返回null，没有新增记录，选择发音弹窗出现，新增
        if (!res.result) {
          setTimeout(() => {
            data.box_data.dialogVoice = true
          }, 2000)
        } else {
          // 返回不为null， 设置过发音模式，拿到接口返回存到本地
          localStorage.setItem(`sound_${store.state.userInfo.realname}`, res.result.fyms ? 'm' : 'y')
        }
      })
      // 判断学生有无集训接口
      //   isHaveTrain({ yhid: store.state.userInfo.yhid, yycdid: sessionStorage.getItem('yycdid') }).then((res) => {
      //     data.jxList = res.result;
      //     if (res.result && res.result.jxid) {
      //       sessionStorage.setItem("jxInfo", JSON.stringify(res.result));
      //       // 助记符图片预加载
      //       getmnemonic({
      //         bjid: res.result.bjid,
      //         jxid: res.result.jxid,
      //         xsid: store.state.userInfo.yhid,
      //         currentPage: 1,
      //         pageSize: 999
      //       }).then(res => {
      //         res.result.pageList.list.forEach(item => {
      //           var oImg = document.createElement('img')
      //           oImg.src = item.tpdz
      //           document.body.append(oImg)
      //         })
      //       })
      //     }
      //   });
    });
    // 登出
    const returnLogin = (val) => {
      if (val == 10) {
        data.exit_data.isShow = true

        // router.replace({ name: "login" });
      } else if (val == 20) {
        router.replace({path: '/setUpPage/information'})
      } else if (val == "myVip") {
        myVip.value.dialogVisible = true
        console.log(1111)
      } else if (val === "goVip") {
        membership.value.drawer = true
      } else {
        // 修改密码
        formRef.value.dialogVisible = true;
      }
    };
    return {
      clickhandler,
      ...toRefs(data),
      store,
      returnLogin,
      popupChange,
      formRef,
      fellow,
      membership,
      myVip,
    };
  },
};
</script>
<style lang="scss" scoped>
.el-dropdown-link{
  display: flex;
}
.home {
  width: 100%;
  height: 100%;
  background: url("../../assets/student/homeBanner.png") no-repeat;
  background-size: 100% 100%;
  box-sizing: border-box;
  padding: 20px 50px 0 50px;
  position: relative;
}

header {
  display: flex;
  justify-content: space-between;
  height: 81px;

  .logo {
    width: 10.16vw;
    height: 3.6vw;

    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  .person {
    // width: 9.81vw;
    height: 4.17vw;
    display: flex;
    justify-content: space-between;

    img {
      display: block;
      width: 2.6vw;
      height: 2.61vw;
      margin-right: 1vw;
    }

    span {
      font-size: 1.56vw;
      font-weight: 600;
      color: #ffffff;
      line-height: 3vw;
    }
  }
}

main {
  width: calc(100% - 10.4vw);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: space-between;

  div {
    flex: 1;

    p {
      text-align: center;
      font-size: 2vw;
      color: #333333;
      line-height: 2vw;
      margin-top: 2vw;
      font-weight: bold;
      cursor: pointer;
    }
  }

  .zjf {
    img {
      display: block;
      width: 12.9vw;
      height: 15.15vw;
      margin: 0 auto;
      cursor: pointer;
    }
  }

  .tuyy {
    img {
      display: block;
      width: 12.9vw;
      height: 15.15vw;
      margin: 0 auto;
      cursor: pointer;
    }
  }

  .xxbg {
    img {
      display: block;
      width: 15.9vw;
      height: 15.15vw;
      margin: 0 auto;
      cursor: pointer;
    }
  }

  .grzx {
    img {
      display: block;
      width: 12.9vw;
      height: 15.15vw;
      margin: 0 auto;
      cursor: pointer;
    }
  }
}
</style>