<template>
  <!-- 选择发音弹窗 -->
  <div class="messagePopup"  id="dialogVoiceBox" ref="dialogVoiceBox">
    <el-dialog v-model="dialogVoice" :close-on-click-modal="false">
      <div class="title">选择发音</div>
      <div class="sysContent">
        <el-form ref="form" label-width="30px">
          <el-form-item>
            <el-radio-group v-model="sysVoices">
              <el-radio label="y">英式</el-radio>
              <el-radio label="m">美式</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
        <div class="dioBtns">
          <div class="btn submitBtn" @click="selectVoice">确 定</div>
        </div>
      </div>
    </el-dialog>
    <div class="maskfy"></div>
  </div>
</template>
<script>
import { onMounted, reactive, ref, toRefs } from 'vue-demi'
import { useStore } from 'vuex'
import { addPersonalConfig } from '@/api/student'
export default {
  props: {
    box_data: {
      type: Object,
    },
  },
  emits: ['popupChange'],
  setup(props, { emit }) {
    const store = useStore()
    onMounted(() => {
      data.dialogVoice = props.box_data.dialogVoice
      data.sysVoices = props.box_data.sysVoices
      document.querySelector('.maskfy').style.opacity = '1'
      dialogVoiceBox.value.style.opacity = '1'
    })
    const data = reactive({
      dialogVoice: true,
      sysVoices: null
    })
    const selectVoice = () => {emit('popupChange', { dialogVoice:false })
      // 只有账号第一次登录会触发
      addPersonalConfig({
        cjr: store.state.userInfo.yhid,
        xgr: store.state.userInfo.yhid,
        xsid: store.state.userInfo.yhid,
        fyms: data.sysVoices == 'y' ? 0 : 1,
      }).then(res => {
        localStorage.setItem(`sound_${store.state.userInfo.realname}`, data.sysVoices)
      }).catch(() => {
      })
    }
    const dialogVoiceBox = ref(null)
    return {
      ...toRefs(data),
      selectVoice,
      dialogVoiceBox
    }
  },
}
</script>
<style lang="scss" scoped>
.maskfy {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  opacity: 0;
  transition: 2s;
  z-index: 1000;
}
#dialogVoiceBox {
  position: fixed;
  top: 80px;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  transition: 2s;
  .title {
    color: #fff;
    position: absolute;
    top: 0;
    font-size: 20px;
    box-sizing: border-box;
    padding: 20px;
    font-weight: bold;
    letter-spacing: 3px;
    width: 100%;
    text-align: left;
    box-sizing: border-box;
  }
  .sysContent {
    width: 100%;
    background: #fff;
    border-radius: 10px;
    padding: 70px 90px 60px;
    box-sizing: border-box;
    .dioBtns {
      overflow: hidden;
      padding-top: 30px;
      .btn {
        width: 166px;
        height: 40px;
        float: left;
        text-align: center;
        border: 2px solid #5990ff;
        border-radius: 6px;
        color: #fff;
        font-size: 18px;
        line-height: 40px;
        margin-left: 50px;
        cursor: pointer;
        background: #5990ff;
      }
    }
  }
}
</style>
<style lang="scss">
  #dialogVoiceBox {
    .el-dialog {
      width: 500px;
      border-radius: 40px;
    }
    .el-dialog__header {
      display: none;
    }
    .el-dialog__body {
      padding: 60px 27px 35px;
      background: #5990ff;
      border-radius: 30px;
    }
    .el-radio__label {
      font-size: 30px;
      color: #999;
    }
    .el-radio__inner {
      width: 20px;
      height: 20px;
      top: -4px;
    }
    .el-radio__input.is-checked .el-radio__inner {
      border-color: #5990ff;
      background: #5990ff;
    }
    .el-radio__input.is-checked + .el-radio__label {
      color: #5990ff;
    }
  }
</style>