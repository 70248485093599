<template>
  <teleport to="body">
    <div class="exitpopup wordbook animate__animated animate__fadeInDown" v-if="outObj.isShow">
      <div class="box">
        <div class="title"> 提&nbsp;&nbsp;&nbsp;示 </div>
        <div class="body">
          <span>
            <img src="../../../assets/student/sigh.png" alt="">
          </span>
          <span>是否退出登录?</span>
        </div>
        <div class="footer">
          <span @click="cancel">取消</span>
          <span @click="sure">确定</span>
        </div>
      </div>
      <div class="mask" @click="maskClick"></div>
    </div>
  </teleport>
</template>
<script>
export default {
  props:{
    exit_data:{
      type:Object,
      default:null
    }
  },
  data(){
    return{
      outObj: {}
    }
  },
  mounted() {
    this.outObj = this.exit_data
  },
  methods:{
    // 取消
    cancel(){
      this.outObj.isShow = false
    },
    // 退出登录
    sure(){
      sessionStorage.clear()
      this.$router.push({name: 'login'})
    },
    // 点击遮罩层
    maskClick(){
      this.outObj.isShow = false
    }
  }
}
</script>
<style lang="scss" scoped>
.exitpopup{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
}
.box{
  width: 25vw;
  height: 10.5vw;
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 2px 2px rgba(0,0,0,.1);
  z-index: 2004;
  .title{
    width: 100%;
    height: 2.5vw;
    background: #5990FF;
    border-radius: 10px  10px 0 0;
    color: #fff;
    font-size: 1vw;
    text-align: center;
    line-height: 2.5vw;
  }
  .body{
    width: 100%;
    height: 4.5vw;
    box-sizing: border-box;
    padding: 1.7vw 2vw 2vw 2vw;
    span:nth-child(1){
      display: block;
      width: 1.7vw;
      height: 1.7vw;
      float: left;
      img{
        display: block;
        width: 100%;
        height: 100%;
      }
    }
    span:nth-child(2){
      display: block;
      float: left;
      margin-left: 3vw;
      font-size: 1.4vw;
      color: #333;
    }
  }
  .footer{
    width: 100%;
    height: 3vw;
    padding: .5vw 0 .5vw 7vw;
    span{
      display: block;
      float: left;
      width: 5vw;
      height: 2vw;
      border-radius: 20px;
      text-align: center;
      line-height: 2vw;
      font-size: 1vw;
      cursor: pointer;
    }
    span:nth-child(1){
      background: #5990FF;
      color: #fff;
    }
    span:nth-child(2){
      background: rgba(89,144,255,.2);
      margin-left: 1vw;
    }
  }
}
.mask{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,.5);
  z-index: 2003;
}
</style>